import React from "react"
import { Link } from "gatsby"
import Clamp from "components/clamp"
import Hero from "components/hero"

export default () => (
  <>
    <Hero>Press release</Hero>
    <Clamp inset>
      <h1>GPI Capital Announces Promotions</h1>
      <p>
        New York, January 14, 2022 – GPI Capital (“GPI”), a leading growth and
        structured equity firm, is pleased to announce the promotion of four
        employees following a landmark year of milestones and accomplishments.
      </p>
      <ul>
        <li>
          Philip Lo to Partner, Investor Relations &amp; Business Development
        </li>
        <li>Chris Kim to Principal, Investments</li>
        <li>Liam Burrell to Principal, Investments</li>
        <li>John Reising to Vice President, Investment</li>
      </ul>
      <p>
        We are very proud of our team and their contributions toward delivering
        value to GPI’s investors and portfolio companies. These promotions
        reflect our strong commitment to a culture of excellence and the drive
        to succeed. We look forward to further growth and development in the
        coming years.
      </p>
      <h2>About GPI Capital</h2>
      <p>
        GPI Capital is an alternative investment firm specializing in leading
        growth equity investments in technology, consumer and industrial
        companies. GPI focuses on identifying high quality businesses looking to
        accelerate growth and execute on transformational opportunities with an
        engaged and value-add partner. For more information about GPI, please
        visit <Link to="/">gpicap.com</Link>
      </p>
    </Clamp>
  </>
)
